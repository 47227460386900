import React from 'react'
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components';
import { MediaStrapiDTO } from '../../models/MediaStrapiDTO';

function StrapiImage(props: MediaStrapiDTO) {
	if (props.attributes.mime !== "video/mp4") return (
		<Wrapper>
			<img
				src={props.attributes.url}
				alt={props.attributes.alternativeText ?? null}
				width={props.attributes.width}
				height={props.attributes.height}
				loading='lazy'
			/>
			{props.attributes.caption && <div className="caption" dangerouslySetInnerHTML={{__html: props.attributes.caption}}/>}
		</Wrapper>
	)
	else return (
		<WrapperVideo>
			<ReactPlayer
				className='video'
				url={props.attributes.url}
				muted
				loop
				playsinline
				playing/>
		</WrapperVideo>
	)
}

export default StrapiImage;

const Wrapper = styled('div')`
	width: 100%;
	height: 100%;
	color: ${({theme}) => theme.colors.primary.base};
	display: contents;
	.caption {
		width: 100%;
		line-height: 1.3;
		font-size: 2.4rem;
		margin-top: 1rem;
		b {
			font-weight: 500;
		}
	}
`;

const WrapperVideo = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	video {
		width: auto!important;
		height: auto!important;
		min-width: 100%;
		min-height: 100%;
	}
`;