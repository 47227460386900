import React from 'react'
import styled from 'styled-components';
import StrapiImage from '../StrapiImage/StrapiImage';
import { MediaStrapiDTO } from '../../models/MediaStrapiDTO';

const MainLogo = (props: MediaStrapiDTO) => {
    return (
        <Wrapper>
            <StrapiImage {...props}/>
        </Wrapper>
    )
}

export default MainLogo;

const Wrapper = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding: 0 5%;

    img {
        max-width: 80px;
        height: auto!important;
    }
`;