import React, { useEffect } from 'react';
import chroma from "chroma-js";
import { ThemeProvider, DefaultTheme } from 'styled-components';


export const theme: Partial<ThemeConfig> = {
    fontSize: {
        base: '1rem',
    }
}

export const darkTheme: ThemeConfig = {
    colors: {
        primary: '#0E0E0E',
        secondary: '#C3FF00',
        third: '#4638FF',
        errors: '#ff3d00',
        success: '#C3FF00',
        warning: '#ffa000',
        grey: '#F2F2F2'
    }
};

export const lightTheme: ThemeConfig = {
    colors: {
        primary: '#0E0E0E',
        secondary: '#C3FF00',
        third: '4638FF',
        errors: '#ff3d00',
        success: '#C3FF00',
        warning: '#ffa000',
        grey: '#F2F2F2'
    },
};

interface ThemeConfig {
    colors: {[Key in Colors]: string}
    fontSize?: {[Key in FontsSize]?: string}
}

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: ColorsType
        fontSize: FontSizeType
        breakpoint: BreakpointType
    }
}

export type Colors = 'primary' | 'secondary' | 'third' | 'errors' | 'success' | 'warning' | 'grey';
export type ScaleColors = 'base' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type ColorsType = {
    [key in Colors]: { [key in ScaleColors]: string }
}
export type FontsSize = 'xs' | 'sm' | 'tiny' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl'
type FontSizeType = {
    [key in FontsSize]: string
}

export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type BreakpointType = {
    [key in BreakpointKey]: string
}


const buildedTheme = (theme: DefaultTheme): DefaultTheme => {
    return {
        ...theme,
        colors: buildVariantColor(theme.colors),
        fontSize: buildFontsSize(theme.fontSize),
        breakpoint: buildBreakpoints(),
    }

}

const buildVariantColor = (colors: any) => {
    Object.keys(colors).map((key) => {
        const color = colors[key as never][500] ?? colors[key as never]
        const darkColor = [500, 600, 700, 800, 900].map((x, i) => (
            { [x]: chroma(color).darken(i/3).name() })
        );
        const lighterColor = [400, 300, 200, 100].map((x, i) => (
            { [x]: chroma(color).brighten(i).name() })
        );
        return colors[key] = Object.assign({}, ...darkColor, ...lighterColor, {base: color} );
    })
    return colors;
}

const buildFontsSize = (fontSize?: FontSizeType) => {
    const keySize = ['xs', 'sm', 'tiny', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl']
    const ratio = (i: number) => i * 0.125;
    const initialCalc = Number((fontSize ? (theme.fontSize as any)[Object.keys(fontSize!)[0]] : '1rem').replace(/[^.0-9]/g, ''))
    const positionKeyInArrSize = fontSize ? keySize.indexOf(Object.keys(fontSize)[0]) : keySize.indexOf('base')
    const calc = Object.assign({}, ...keySize.map((x, i) => (
        {[x]: `${(positionKeyInArrSize - +i) < 0 ?
            initialCalc + ratio(Math.abs(positionKeyInArrSize - +i))
            : initialCalc - ratio(Math.abs(positionKeyInArrSize - +i)) 
        }rem` }
    )))
    return { ...calc, ...fontSize};
}
export const breakpointSizeKey = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']
export const breakpointSize = ['640px', '640px', '768px', '1024px', '1280px', '1536px', '1536px']
const buildBreakpoints = () => {
    const mediaQueries = breakpointSizeKey.map((x, i) => (
        i === 0 ?
        {[x]: `only screen and (max-width: ${breakpointSize[i]})`} :
        (i + 1) < breakpointSize.length ?
        {[x]: `only screen and (min-width: ${breakpointSize[i]}) and (max-width: ${breakpointSize[i+1]})`} :
        {[x]: `only screen and (min-width: ${breakpointSize[i]})`}
    )); 
    return Object.assign({}, ...mediaQueries);
}

export const breakpoint = (value: BreakpointKey) => {
    return `@media ${buildBreakpoints()[value]}`
}

const Theme: React.FC<any> = ({children, isDark}: any) => {
    const themeSet = isDark ? darkTheme : lightTheme;
    const T =  Object.assign(theme, themeSet);
    const newTheme = buildedTheme(T as any);

    useEffect(() => {
        if(isDark) document.querySelector('html')?.classList.add('dark')
        else document.querySelector('html')?.classList.remove('dark')
    }, [isDark])

    //@ts-ignore
    return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default Theme;