import React, { useState } from 'react'
import styled from 'styled-components';
import ShareButtons from '../ShareButtons/ShareButtons';
import { HomeDTO } from '../../models/HomeDTO';

const Header = ({content}: {content: HomeDTO}) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <Wrapper>
            {renderIcon()}
            {renderMenuContent()}
        </Wrapper>
    );

    function renderIcon(){
        return (
            <MenuIcon onClick={() => setOpen(!open)} className={`${!open ? 'fixed' : ''}`}>
                {!open ? 
                    <img src={process.env.PUBLIC_URL + '/assets/menu.svg'} alt=''/>
                    : <img src={process.env.PUBLIC_URL + '/assets/close.svg'} alt=''/>
                }
            </MenuIcon>
        );
    }

    function renderMenuContent(){
        return (
            <WrapperMenuContent className={!open ? 'close' : 'open'}>
                <ShareButtons/>
                <div className="foot">
                    <h4>{content.titleInnerMenu}</h4>
                    <p>{content.descriptionInnerMenu}</p>
                    <div className="btn-foot">
                        <a href={content.hrefButtonInnerMenu} rel={'noopener'}>
                            {content.textButtonInnerMenu}
                        </a>
                    </div>
                </div>
            </WrapperMenuContent>
        );
    }
}

export default Header;

const Wrapper = styled('header')`
    width: 100%;
    padding: 20px 5%;
`;

const MenuIcon = styled('div')`
    position: absolute;
    top: 30px;
    right: 5%;
    width: 24px;
    height: 24px;
    z-index: 11;

    &.fixed {
        position: fixed;
    }

    img {
        max-width: 100%;
    }
`;

const WrapperMenuContent = styled('div')`
    width: 100%;
    height: 0%;
    background-color: ${({theme}) => theme.colors.primary.base};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    transition: 500ms ease-in-out;
    color: ${({theme}) => theme.colors.primary[100]};
    overflow-y: scroll;

    &.open {
        height: 100%;
        padding-top: 100px;
        padding-bottom: 40px;
    }

    &.close {
        height: 0%;
        padding-top: 0px;
    }

    .foot {
        width: 100%;
        padding: 0 10%;
        margin-top: 50px;

        h4 {
            color: #fff;
            font-size: 20px;
            margin-bottom: 5px;
            font-weight: 500;
        }

        a {
            display: inline-block;
            margin-top: 20px;
            color: #fff;
            padding: 3px 0;
            border-bottom: 1px solid ${({theme}) => theme.colors.secondary.base};
            text-decoration: none;
        }
    }
`;