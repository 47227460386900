import React from 'react'
import { keyframes, styled } from 'styled-components';

function Badge({text}: any) {
    if(!text || text.length < 1) return null;
    return (
        <Wrapper>
            <BadgeInner dangerouslySetInnerHTML={{__html: text}}/>
        </Wrapper>
    )
}

export default Badge

const animation = keyframes`
    0% {
        background-color: #ffda79
    }

    25% {
        background-color: #ffabe7
    }

    50% {
        background-color: #d2bcf3
    }

    75% {
        background-color: #D3FF4F
    }

    100% {
        background-color: #ffda79
    }
`;

const Wrapper = styled('div')`
    width: 100%;
    margin: 30px 0 0 0;
    padding: 5px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BadgeInner = styled('div')`
    background: #FFDA79;
    padding: 8px 20px 9px 20px;
    font-size: 13px;
    border-radius: 50px;
    animation: ${animation} 20s linear infinite;
`;