// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import Home from './pages/Home';
import Layout from './components/Layout';

function App() {
	return (
		<Layout>
			<Home/>
		</Layout>
	)
}

export default App;
