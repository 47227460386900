import { get } from "./ApiService";


const filterCollections = (where: string, eq: string) => `&filters${where}[$eqi]=${eq}`;
const contentPopulate = `link.image,icon.icon,logo`;

const api = {
    starPage: (lang: string) => {
        return get(`/start-page/?populate=${contentPopulate}&locale=${lang}`)
    },
    blog: (lang: string) => {
        return get(`/pages/?populate=${contentPopulate}&locale=${lang}${filterCollections('[name]', 'Journal')}`)
    },
    articles: (lang: string, page: string = '1', categorySlug?: string) => {
        return get(`/articles/?populate=media,coverCard,categories_blog,localizations&locale=${lang}&sort=publishedAt&pagination[page]=${page}${categorySlug ? filterCollections('[categories_blog][slug]', categorySlug) : ''}`)
    },
    categoriesBlog: (lang: string) => {
      return get(`/categories-blogs/?populate=*&locale=${lang}`)
    }
}

export default api;