import React from 'react'
import styled from 'styled-components';

function Layout({children}: any) {
    return (
        <WrapperLayout>
            {children}
        </WrapperLayout>
    )
}

export default Layout;

const WrapperLayout = styled('main')`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
`;