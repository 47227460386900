import axios from "axios";
import Constants from "../config/Constants";


const axiosInstance = axios.create({ baseURL: Constants.API_URL});
const defaultHeaders: any = {
    Accept: "*/*",
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
};

axiosInstance.interceptors.request.use(
    async (config) => config,
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    async (config) => config,
    (error) => Promise.reject(error)
);

export const get = async (endpoint: string, useToken: boolean = true, blob: boolean = false) => {
    try {
        let header = {}
        if (useToken) {
            defaultHeaders.Authorization = `Bearer ${Constants.API_TOKEN}`;
            header = { headers: defaultHeaders, responseType: blob ? 'blob' : 'json' }
        }
        const {data} = await axiosInstance.get(`${endpoint}`, header)
        return data;
    } catch (error) {
        //TODO - handle error
        return { error: { messageCode: (error as any).message } };
    }
}

export const post = async (endpoint: string, object: any, useToken: boolean = true) => {
    try {
        let header = {}
        if (useToken) {
            defaultHeaders.Authorization = `Bearer ${Constants.API_TOKEN}`;
            header = { headers: defaultHeaders }
        }
        let { data } = await axiosInstance.post(endpoint, object, { ...header });
        return data;
    } catch (error) {
        //TODO - handle error
        return { error: { messageCode: (error as any).message } };
    }
}

export default axiosInstance;