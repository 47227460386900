import React from 'react'
import styled from 'styled-components';
import StrapiImage from '../StrapiImage/StrapiImage';
import { MediaStrapiDTO } from '../../models/MediaStrapiDTO';

interface ButtonLinkProps {
    text: string
    href: string
    media: {data?: MediaStrapiDTO}
}

const ButtonLink: React.FC<ButtonLinkProps> = (props) => {
    return (
        <WrapperButton>
            <a href={props.href} className='btn' rel={'noopener'}>
                {props.media.data && <div className="img">
                    <StrapiImage {...props.media.data}/>
                </div>}
                <span>{props.text}</span>
            </a>
        </WrapperButton>
    )
}

export default ButtonLink;

const WrapperButton = styled('div')`
    width: 100%;
    padding: 0 5%;
    text-align: center;
    margin-bottom: 15px;
    .btn {
        width: 100%;
        display: inline-block;
        background: ${({theme}) => theme.colors.primary.base};
        padding: 8px;
        border-radius: 40px;
        position: relative;
        height: 53px;
        font-size: 15px;
        color: #fff;
        text-decoration: none;

        span {
            user-select: none;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }

        .img {
            max-width: 38px;
            aspect-ratio: 1 / 1;
            background: ${({theme}) => theme.colors.grey.base};
            border-radius: 50%;
            position: absolute;
            pointer-events: none;

            div {
                display: block;
            }

            img {
                max-width: 100%;
                width: auto!important;
                height: auto!important;
            }
        }
    }
`;