import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components';
import api from '../../api/Api';
import i18n from '../../i18n/i18n-config';
import { t } from 'i18next';

function JournalSection() {

    const [articles, setArticles] = useState<any[] | undefined>(undefined)

    useEffect(() => {
        (async () => {
            const articlesResponse = await api.articles(i18n.resolvedLanguage ?? 'it')
            if(articlesResponse.data.length < 0) return null;
            setArticles(articlesResponse.data)
        })()
    }, [i18n.resolvedLanguage])

    if (!articles || articles.length < 1) return null;
    return (
        <WrapperSection>
            <WrapperTitle>
                <img src="https://trebarrasette.com/_next/image?url=%2Fassets%2Flogo-black-250.png&w=256&q=80" alt="" />
                 JOURNAL
            </WrapperTitle>
            <p className='d'>{t('share.description')}</p>
            {renderSectionCards()}
        </WrapperSection>
    );

    function renderSectionCards() {
        return (
            <WrapperGrid>
                {articles?.map((x, i) => (
                    <a key={i} href={`https://trebarrasette.com/${i18n.resolvedLanguage ?? 'it'}/journal/${x.attributes.categories_blog.data.attributes.slug}/${x.attributes.slug}`} target='_blank' rel="noreferrer">
                        <ArticleCard style={{backgroundImage: `url(${x.attributes.coverCard.data.attributes.url})`}}>
                            <div className='title'>
                                <p>{x.attributes.title}</p>
                                <div className="tag">
                                    {x.attributes.categories_blog.data.attributes.name}
                                </div>
                            </div>
                        </ArticleCard>
                    </a>
                ))}
            </WrapperGrid>
        );
    }
}

export default JournalSection;

const WrapperSection = styled('div')`
    width: 100%;
    padding-bottom: 40px;

    p.d {
        width: 100%;
        padding: 0 5%;
        text-align: center;
        margin-top: 10px;
    }
`;

const WrapperGrid = styled('div')`
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    margin: 20px 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    padding-bottom: 10px;

    a {
        position: relative;
        display: inline-block;
        margin-right: 13px;
    }

    &::-webkit-scrollbar { display: none; }
`;

const ArticleCard = styled('div')`
    min-width: 220px;
    height: 250px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: ${({theme}) => theme.colors.grey[500]};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 18px;
    position: relative;

    .title {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 15px 13px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: linear-gradient(180deg, rgba(23, 22, 22, 0.10) 0%, rgba(0, 0, 0, 0.70) 100%);

        p {
            white-space: normal;
            color: #fff;
            font-weight: 500;
            font-size: 18px;
        }

        .tag {
            width: fit-content;
            padding: 2px 8px 3px 8px;
            border: 1px solid rgba(255,255,255, .7);
            margin-top: 20px;
            color: #fff;
            display: block;
            border-radius: 30px;
            font-size: 14px;
        }
    }
`;

const WrapperTitle = styled('div')`
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 3.9rem;
    font-weight: 500;

    img {
        max-width: 50px;
        margin-right: 7px;
    }
`;