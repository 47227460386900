import React, { useEffect, useState } from 'react'
import api from '../api/Api'
import { styled } from 'styled-components'
import Header from '../components/Header/Header'
import { useTranslation } from 'react-i18next';
import { HomeDTO } from '../models/HomeDTO';
import MainLogo from '../components/MainLogo/MainLogo';
import Badge from '../components/Badge/Badge';
import ButtonLink from '../components/ButtonLink/ButtonLink';
import IconLink from '../components/IconLink/IconLink';
import JournalSection from '../components/JournalSection/JournalSection';


function Home() {

    const [content, setContent] = useState<HomeDTO | null>(null)
    const { i18n } = useTranslation();

    useEffect(() => {
        (async () => {
            const response = await api.starPage(i18n.resolvedLanguage ?? 'it')
            if(!response.data) return null;
            setContent(response.data.attributes)
        })()
    }, [i18n.resolvedLanguage])

    return (
        <Wrapper id={`${i18n.resolvedLanguage}`}>
            {content && <Header content={content}/>}
            {renderLogo()}
            {renderBadge()}
            <ContainerText>
                {renderHeadline()}
                {renderDescription()}
            </ContainerText>
            {renderLinkButton()}
            <ContainerIcon>
                {renderLinkIcon()}
            </ContainerIcon>
            <JournalSection/>
            <WrapperFoot>
                <img src={process.env.PUBLIC_URL + '/assets/logotype.svg'} alt=''/>
            </WrapperFoot>
        </Wrapper>
    )
    
    function renderLogo(){
        if(!content) return null;
        if(!content.logo || !content.logo.data) return null;
        return (
            <MainLogo {...content.logo.data}/>
        );
    }

    function renderBadge(){
        if(!content) return null;
        return (
            <Badge text={content.badge}/>
        );
    }

    function renderHeadline(){
        if(!content) return null;
        return (
            <h1 dangerouslySetInnerHTML={{__html: content.Headline.replaceAll('®', '<sup>®</sup>').replaceAll('™', '<sup>™</sup>') }}></h1>
        );
    }

    function renderDescription(){
        if(!content || !content.content) return null;
        return (
            <p dangerouslySetInnerHTML={{__html: content.content.replaceAll('®', '<sup>®</sup>').replaceAll('™', '<sup>™</sup>') }}></p>
        ); 
    }

    function renderLinkButton(){
        if(!content) return null;
        if(!content.link || content.link.length < 1) return null;

        return content?.link.map((x, i) => (
            <ButtonLink key={i} text={x.text} href={x.href} media={x.image}/>
        ))
    }

    function renderLinkIcon(){
        if(!content) return null;
        if(!content.icon || content.icon.length < 1) return null;

        return content?.icon.map((x, i) => (
            <IconLink key={i} href={x.href} media={x.icon}/>
        ))
    }


}

export default Home;

const Wrapper = styled('div')`
    width: 100%;
    margin: 0;
`;

const ContainerText = styled('div')`
    width: 100%;
    padding: 0 5%;
    text-align: center;
    line-height: 140%;
    margin-bottom: 50px;
    margin-top: 20px;

    h1 {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    p {
        font-size: 16px;
    }
`;

const ContainerIcon = styled('div')`
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-around;
    margin: 90px 0 40px 0;
`;

const WrapperFoot = styled('div')`
    width: 100%;
    padding: 10px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    img {
        max-width: 180px;
    }
`;