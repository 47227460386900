import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'next-share';
import React, { useState } from 'react'
import styled from 'styled-components'
import Constants from '../../config/Constants';
import { t } from 'i18next';

function ShareButtons() {
    const [copied, setCopied] = useState(false);

    return (
        <Wrapper>
            <div className="list">
                <FacebookShareButton url={Constants.URL}
                    quote={t('share.title')}
                    hashtag={'#trebarrasette'}>
                    <FacebookIcon size={32} round />
                    <span>{t('share.on')} Facebook</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </FacebookShareButton>
                <LinkedinShareButton url={Constants.URL}>
                    <LinkedinIcon size={32} round />
                    <span>{t('share.on')} Linkedin</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </LinkedinShareButton>
                <TwitterShareButton url={Constants.URL}
                    title={t('share.title')}>
                    <TwitterIcon size={32} round />
                    <span>{t('share.on')} X / Twitter</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </TwitterShareButton>
                <TelegramShareButton url={Constants.URL}
                    title={t('share.title')}>
                    <TelegramIcon size={32} round />
                    <span>{t('share.via')} Telegram</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </TelegramShareButton>
                <WhatsappShareButton url={Constants.URL}
                    title={t('share.title')}
                    separator=":: ">
                    <WhatsappIcon size={32} round />
                    <span>{t('share.via')} Whatsapp</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </WhatsappShareButton>
                <EmailShareButton url={Constants.URL} subject={'Next Share'}
                    body="body">
                    <EmailIcon size={32} round />
                    <span>{t('share.via')} Email</span>
                    <img className='arrow' src={process.env.PUBLIC_URL + '/assets/arrow.svg'} alt=''/>
                </EmailShareButton>
                <div className="btn-copy">
                    <img src={process.env.PUBLIC_URL + '/assets/logo.webp'} alt="" />
                    <p>{Constants.URL.replace('https://', '')}</p>
                    <div className="copy" onClick={() => {
                        navigator.clipboard.writeText(Constants.URL.replace('https://', ''))
                        setCopied(true);
                    }}>
                        {copied ? 'Copied' : '⌘C'}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default ShareButtons

const Wrapper = styled('div')`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .list {
        padding: 0 10%;
        width: 100%;
        display: grid;
        grid-template-columns: 1;
        grid-gap: 1rem;
        align-items: center;

        .btn-copy {
            background: #fff;
            padding: 20px 10px;
            border-radius: 13px;
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 40px;

            img {
                max-width: 30px;
                margin-right: 20px;
            }
            p {
                font-size: 17px;
                color: ${({theme}) => theme.colors.primary.base};
            }

            .copy {
                position: absolute;
                right: 20px;
            }
        }
    }

    button {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        padding-top: 20px!important;

        &:first-child {
            &::after {
                height: 0px!important;
            }
        }

        .arrow {
            position: absolute;
            right: 0;
        }

        span {
            margin-left: 2rem;
            font-weight: 500;
        }

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background: ${({theme}) => theme.colors.primary[300]};
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`;