import React from 'react'
import { MediaStrapiDTO } from '../../models/MediaStrapiDTO'
import StrapiImage from '../StrapiImage/StrapiImage'
import { styled } from 'styled-components'

interface IconLinkProps {
    href: string
    media: {data?: MediaStrapiDTO}
}

const IconLink: React.FC<IconLinkProps> = (props) => {
    return (
        <WrapperIcon>
            <a href={props.href} className='btn-icon' rel={'noopener'}>
                {props.media.data && <div className="img">
                    <StrapiImage {...props.media.data}/>
                </div>}
            </a>
        </WrapperIcon>
    )
}

export default IconLink;

const WrapperIcon = styled('div')`
    width: fit-content;

    img {
        max-width: 24px;
        width: auto!important;
        height: auto!important;
    }
`;